import { Options } from 'ky';
import shop from '~/lib/api/shop';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';

export default function getPrices(
    signal: AbortSignal | undefined,
    ids: string[] | undefined,
    customerId?: string | null,
    token?: string | null,
) {
    if (!ids?.length) return Promise.reject(new Error(Shop.errors.invalidPriceItemNumbers.message));

    const url = Shop.utils.getUrl(`/prices/foritemnumbers`);

    if (customerId) url.searchParams.set('customerId', customerId);

    const payload : Options = {
        json: ids,
        signal        
    };
    if(token){
        payload.headers = {
            Authorization: `Bearer ${token}`,
        }
    }
    return shop
        .post(url, payload)
        .json<ShopTypes.PriceWithCampaigns[]>();
}
