import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { Button } from '~/shared/components/Button';
import { breakpoints } from '~/theme';

export const Container = styled.nav({
    gridColumn: '1 / -1',

    [breakpoints.sm]: {
        display: 'none',
    },
});

export const List = styled.ul(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    margin: `0 -${theme.space[3]}`,
    padding: `0 ${theme.space[3]}`,
    gap: theme.space[4],
    overflowX: 'auto',
    scrollbarGutter: 'stable',
    ['&::-webkit-scrollbar']: {
        display: 'none',
    },
    marginBottom: theme.space[5],
}));

export const ItemButton = styled(Button)<{ isSelected: boolean }>(
    ({ theme, variant }) => ({
        whiteSpace: 'nowrap',
        height: theme.lineHeights.wolf,
        borderColor: theme.colors.grey50,
        backgroundColor: variant === 'stroke' ? theme.colors.white : '',

        'div, span, svg': {
            marginRight: theme.space[1],
        },

        [breakpoints.sm]: {
            ':hover': {
                borderColor: theme.colors.brandBlue,

                'div, span': {
                    colors: theme.colors.brandBlue,
                },
            },
        },
    }),

    switchProp('variant', {
        link: ({ theme }) => ({
            fontWeight: theme.fontWeights.medium,

            'div, span, svg': {
                marginRight: theme.space[1],
            },
        }),
    }),

    ifProp('isSelected', ({ theme }) => ({
        fontWeight: theme.fontWeights.medium,
    })),
);

export const ListItem = styled.li<{ isActive?: boolean }>(
    ({ theme }) => ({
        whiteSpace: 'nowrap',
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.fox,
    }),
    ifProp('isActive', () => ({
        listStyle: 'square',
        backgroundColor: '#f00',
    })),
);

export const NavigationFilterWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[1],
    marginBottom: theme.space[4],
    msOverflowStyle: 'none',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&:scrollbar': {
        display: 'none',
    },
    whiteSpace: 'nowrap',
    width: '100%',
    order: 2,
    listStyle: 'none',

    [breakpoints.sm]: {
        display: 'none',
    },
}));
