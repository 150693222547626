import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent, GAEcommerceItem } from '../utils/types';

export function removeFromCart(
    items: GAEcommerceItem[],
    value: number,
    basketReference: string | undefined,
) {
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
        event: GAEcommerceEvent.RemoveFromCart,
        ecommerce: {
            basketReference: basketReference,
            currency: 'DKK',
            value,
            items,
        },
    });
}
