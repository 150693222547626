import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';
import { useConfig } from '~/shared/hooks';
import gtm from '~/shared/tracking/gtm';
import { Cookie } from '../utils/cookie';

export function GoogleTagManagerScript() {
    const { GTM_ID } = useConfig();
    const router = useRouter();
    const { data: page, isError } = usePage<UmbracoTypes.IPages>();
    const initialLoad = useRef(true);

    const { status } = useSession();

    useEffect(() => {
        // Save the original location in session storage
        if (!sessionStorage.getItem(Cookie.ogLocation)) {
            sessionStorage.setItem(
                Cookie.ogLocation,
                window.location.pathname + window.location.search,
            );
        }
    }, []);

    useEffect(() => {
        const onRouteChangeComplete = (url: string) => {
            // Check if URL is defined, the page is defined and there is no error
            if (!(!!url && (page !== undefined || isError)) && router.isReady) {
                return;
            }
            gtm.views.virtualPageView(url, page?.type, page?.meta.title, status);
        };
        if (initialLoad.current) {
            onRouteChangeComplete(router.asPath);
            initialLoad.current = false;
        }

        router.events.on('routeChangeComplete', onRouteChangeComplete);

        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router, page, isError]);

    return (
        <Script
            id="gtag-base"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://tracking.johannesfog.dk/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${GTM_ID}');
                `,
            }}
        />
    );
}
