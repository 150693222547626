import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Button } from '~/shared/components/Button';
import { breakpoints } from '~/theme';

export const FilterButton = styled(Button)(
    ({ theme }) => ({
        height: theme.lineHeights.wolf,
        borderColor: theme.colors.grey50,
        backgroundColor: theme.colors.white,

        'div, span, svg': {
            marginRight: theme.space[1],
        },

        [breakpoints.sm]: {
            ':hover': {
                borderColor: theme.colors.brandBlue,

                'div, span': {
                    colors: theme.colors.brandBlue,
                },
            },
        },
    }),
    switchProp('variant', {
        link: ({ theme }) => ({
            backgroundColor: 'transparent',
            fontWeight: theme.fontWeights.medium,
        }),
    }),
);
