import { useCallback, useMemo, useState } from 'react';
import { EXCLUDED_FACET_LIST, PriceRange } from '~/features/ProductList';
import ArrowLeft from '~/icons/iconography/arrow/left.svg';
import ChevronRight from '~/icons/iconography/chevron/right.svg';
import CloseIcon from '~/icons/iconography/close.svg';
import { Loop54Types } from '~/lib/data-contract';
import { useFilterDefaults, useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { MultipleSelector } from '~/shared/components/MultipleSelector';
import { useUrlQueryState } from '~/shared/hooks';
import { theme } from '~/theme';
import {
    getFilterButtonText,
    getTranslatedFacetName,
    getTranslatedFacetValue,
} from '../helpers/filterHelper';
import {
    FacetSelectionList,
    FilterList,
    HeaderButton,
    MobileFilterButton,
    MobileFiltersFooter,
    MobileFiltersWrapper,
    MultipleSelectorWrapper,
    PriceRangeWrapper,
} from './styled';
import gtm from '~/shared/tracking/gtm';
import { useSession } from 'next-auth/react';

interface MobileFiltersProps {
    facets?: (Loop54Types.DistinctFacet | Loop54Types.RangeFacet)[];
    onClose?: () => void;
    resultCount?: number;
}

interface Filter {
    label: string;
    value: string;
    count?: number;
}

export default function MobileFilters({ facets, onClose, resultCount }: MobileFiltersProps) {
    const { status } = useSession();
    const { translate } = useTranslations();
    const [selectedFacetName, setSelectedFacetName] = useState<string>();
    const [selectedFilters, setSelectedFilters, clearSelectedFilters] = useUrlQueryState();
    const { data: filterDefaults } = useFilterDefaults();

    const { facets: allFacets } = filterDefaults ?? {};

    // Remove Price filter when logged in
    const filteredFacets =
        status === 'unauthenticated'
            ? facets
            : facets?.filter((facet) => facet.name !== 'PriceInclVat');

    const selectedFiltersCount =
        Object.entries(selectedFilters)
            // Skip excluded facets, e.g. ContextGroup
            ?.filter(([facetName]) => !EXCLUDED_FACET_LIST.includes(facetName))
            // Only show valid facets that exists
            ?.filter(([facetName]) => Object.keys(allFacets ?? {}).includes(facetName))
            ?.flatMap((filter) => filter)?.length || 0;

    const onSelectedFilterHandler = useCallback(
        (newSelectedFilters: Filter[]) => {
            if (selectedFacetName) {
                const translatedFacetName = getTranslatedFacetName(selectedFacetName, translate);
                if (
                    (selectedFilters[selectedFacetName]?.length ?? 0) < newSelectedFilters.length &&
                    translatedFacetName
                ) {
                    gtm.custom.filters(translatedFacetName);
                }

                setSelectedFilters(
                    selectedFacetName,
                    newSelectedFilters.map((filter) => filter.value),
                );
            }
        },
        [selectedFacetName, selectedFilters, setSelectedFilters, translate],
    );

    const onRemoveFilterClickHandler = useCallback(
        (facetName: string, removedFilter: string) => {
            setSelectedFilters(
                facetName,
                selectedFilters[facetName].filter((filter) => filter !== removedFilter),
            );
        },
        [selectedFilters, setSelectedFilters],
    );

    const selectedFilterElements = useMemo(
        () =>
            Object.entries(selectedFilters)
                // Skip excluded facets, e.g. ContextGroup
                ?.filter(([facetName]) => !EXCLUDED_FACET_LIST.includes(facetName))
                // Only show valid facets that exists
                ?.filter(([facetName]) => Object.keys(allFacets ?? {}).includes(facetName))
                .flatMap(([facetName, filterList]) =>
                    filterList.map((filter) => (
                        <li key={filter}>
                            <Button
                                color={theme.colors.offWhite}
                                noIcon
                                onClick={() => {
                                    onRemoveFilterClickHandler(facetName, filter);
                                }}
                                type="button"
                                variant="stroke"
                            >
                                <CloseIcon />

                                {getFilterButtonText(
                                    getTranslatedFacetName(facetName, translate) || facetName,
                                    getTranslatedFacetValue(facetName, filter, translate) || filter,
                                )}
                            </Button>
                        </li>
                    )),
                ),
        [onRemoveFilterClickHandler, selectedFilters, allFacets, translate],
    );

    const facetSelectionElement = useMemo(() => {
        return (
            <>
                <HeaderButton
                    color={theme.colors.brandBlue}
                    customIcon={<CloseIcon />}
                    onClick={onClose}
                    type="button"
                >
                    {translate('navigation.filters')}
                </HeaderButton>

                {selectedFilterElements.length < 1 ? null : (
                    <FilterList>{selectedFilterElements}</FilterList>
                )}

                {(filteredFacets?.length || 0) < 1 ? null : (
                    <FacetSelectionList>
                        {filteredFacets?.map((facet) =>
                            'items' in facet && (facet?.items?.length || 0) < 1 ? null : (
                                <li key={facet.name}>
                                    <MobileFilterButton
                                        color={theme.colors.nightBlack}
                                        customIcon={<ChevronRight />}
                                        onClick={() => {
                                            setSelectedFacetName(facet.name);
                                        }}
                                        type="button"
                                        variant="stroke"
                                    >
                                        {getTranslatedFacetName(facet.name, translate)}
                                    </MobileFilterButton>
                                </li>
                            ),
                        )}
                    </FacetSelectionList>
                )}

                <MobileFiltersFooter>
                    <Button
                        color={theme.colors.offWhite}
                        noIcon
                        onClick={clearSelectedFilters}
                        type="button"
                        variant="stroke"
                    >
                        {translate('navigation.filter.removeAll')} ({selectedFiltersCount})
                    </Button>

                    <Button
                        color={theme.colors.offWhite}
                        noIcon
                        onClick={() => {
                            setSelectedFacetName(undefined);
                            onClose?.();
                        }}
                        type="button"
                    >
                        {translate('navigation.filter.results')}{' '}
                        {resultCount ? `(${resultCount})` : null}
                    </Button>
                </MobileFiltersFooter>
            </>
        );
    }, [
        clearSelectedFilters,
        filteredFacets,
        onClose,
        resultCount,
        selectedFilterElements,
        selectedFiltersCount,
        translate,
    ]);

    const facetFilterElement = useMemo(() => {
        const selectedFacet = filteredFacets?.find((facet) => facet.name === selectedFacetName);

        switch (selectedFacet?.type) {
            case 'distinct': {
                const distinctFacet = selectedFacet as Loop54Types.DistinctFacet;

                return (
                    <>
                        <HeaderButton
                            color={theme.colors.brandBlue}
                            customIcon={<CloseIcon />}
                            onClick={() => {
                                setSelectedFacetName(undefined);
                            }}
                            type="button"
                        >
                            <ArrowLeft />
                            {getTranslatedFacetName(selectedFacetName, translate)}
                        </HeaderButton>

                        <MultipleSelectorWrapper key={selectedFacetName}>
                            <MultipleSelector
                                id={distinctFacet.name || 'multiple-selector'}
                                options={
                                    distinctFacet?.items?.map((item) => ({
                                        count: item.count,
                                        label:
                                            getTranslatedFacetValue(
                                                selectedFacetName,
                                                item.item?.toString(),
                                                translate,
                                            ) || '',
                                        value: item.item?.toString() || '',
                                    })) || []
                                }
                                initialSelectedItems={
                                    selectedFacetName ? selectedFilters[selectedFacetName] : []
                                }
                                label={
                                    getTranslatedFacetName(selectedFacetName, translate) ||
                                    'distinct'
                                }
                                noLabel
                                onSelectedItemsChange={onSelectedFilterHandler}
                            />
                        </MultipleSelectorWrapper>

                        <MobileFiltersFooter>
                            <Button
                                color={theme.colors.offWhite}
                                noIcon
                                onClick={() => {
                                    setSelectedFilters(distinctFacet.name!, []);
                                }}
                                type="button"
                                variant="stroke"
                            >
                                {translate('generic.reset')}
                            </Button>

                            <Button
                                color={theme.colors.offWhite}
                                noIcon
                                onClick={() => {
                                    setSelectedFacetName(undefined);
                                    onClose?.();
                                }}
                                type="button"
                            >
                                {translate('navigation.filter.results')}{' '}
                                {resultCount ? `(${resultCount})` : null}
                            </Button>
                        </MobileFiltersFooter>
                    </>
                );
            }

            case 'range': {
                const rangeFacet = selectedFacet as Loop54Types.RangeFacet;

                const label =
                    getTranslatedFacetName(selectedFacetName, translate) ?? rangeFacet.name;

                // Subscription closure to enable method triggering inside PriceRange
                const rangeFilterSubscription = (() => {
                    let updateSubscriber: (() => void) | undefined;
                    let resetSubscriber: (() => void) | undefined;

                    return {
                        subscribe: (update: () => void, reset: () => void) => {
                            updateSubscriber = update;
                            resetSubscriber = reset;
                        },
                        unsubscribe: () => {
                            updateSubscriber = undefined;
                            resetSubscriber = undefined;
                        },
                        publishUpdate: () => {
                            updateSubscriber?.();
                            if (label) gtm.custom.filters(label);
                        },
                        publishReset: () => {
                            resetSubscriber?.();
                        },
                    };
                })();

                return (
                    <>
                        <HeaderButton
                            color={theme.colors.brandBlue}
                            customIcon={<CloseIcon />}
                            onClick={() => {
                                rangeFilterSubscription.publishUpdate();
                                setSelectedFacetName(undefined);
                            }}
                            type="button"
                        >
                            <ArrowLeft />
                            {getTranslatedFacetName(selectedFacetName, translate)}
                        </HeaderButton>

                        <PriceRangeWrapper key={rangeFacet.name}>
                            <PriceRange
                                min={rangeFacet.min as number}
                                max={rangeFacet.max as number}
                                name={rangeFacet.name || 'range'}
                                onClose={() => {
                                    setSelectedFacetName(undefined);
                                }}
                                rangeSubscription={rangeFilterSubscription}
                            />
                        </PriceRangeWrapper>

                        <MobileFiltersFooter>
                            <Button
                                color={theme.colors.offWhite}
                                noIcon
                                onClick={() => {
                                    rangeFilterSubscription.publishReset();
                                    setSelectedFilters(rangeFacet.name!, []);
                                }}
                                type="button"
                                variant="stroke"
                            >
                                {translate('generic.reset')}
                            </Button>

                            <Button
                                color={theme.colors.offWhite}
                                noIcon
                                onClick={() => {
                                    rangeFilterSubscription.publishUpdate();
                                    setSelectedFacetName(undefined);
                                    onClose?.();
                                }}
                                type="button"
                            >
                                {translate('navigation.filter.results')}{' '}
                                {resultCount ? `(${resultCount})` : null}
                            </Button>
                        </MobileFiltersFooter>
                    </>
                );
            }

            default: {
                return null;
            }
        }
    }, [
        filteredFacets,
        onClose,
        onSelectedFilterHandler,
        resultCount,
        selectedFacetName,
        selectedFilters,
        setSelectedFilters,
        translate,
    ]);

    return (
        <MobileFiltersWrapper>
            {!selectedFacetName ? facetSelectionElement : facetFilterElement}
        </MobileFiltersWrapper>
    );
}
