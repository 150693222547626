import { ShopTypes } from '~/lib/data-contract';
import { Loop54Product, useTranslations } from '~/services';
import { useCurrency } from '~/shared/hooks';
import { PriceWrapper, BuyPrice, SalesUnit, NormalPrice, Amount } from './styled';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';

interface Props {
    product: Loop54Product;
    translate: ReturnType<typeof useTranslations>['translate']; // Pass hooks in to avoid to many listeners to react query
    className?: string;
    myPrice?: ShopTypes.PriceWithCampaigns;
    myPriceIsLoading?: boolean;
    showPriceExVat?: boolean;
    showOriginalPrice?: boolean;
}

export const Price = ({
    product,
    translate,
    className,
    myPrice,
    myPriceIsLoading,
    showPriceExVat,
    showOriginalPrice = true,
}: Props) => {
    const formatCurrency = useCurrency();

    const productPrice = showPriceExVat ? product.PriceExclVat : product.PriceInclVat;
    const normalPrice = showPriceExVat ? product.OriginalPriceExclVat : product.OriginalPriceInclVat;
    const productUnit = product.SalesUnit;
    const altProductPrice = showPriceExVat
        ? product.AlternativePriceExclVat
        : product.AlternativePriceInclVat;
    const altProductUnit = product.ProductAlternativeSalesUnit;

    const myProductPrice = showPriceExVat
        ? myPrice?.price?.price.priceExVat
        : myPrice?.price?.price.priceInclVat;
    const myAltProductPrice = showPriceExVat
        ? myPrice?.alternativePrice?.price.priceExVat
        : myPrice?.alternativePrice?.price.priceInclVat;

    const myOriginalPrice = showPriceExVat
        ? myPrice?.price?.originalPrice?.priceExVat
        : myPrice?.price?.originalPrice?.priceInclVat;

    const buyPrice = myProductPrice ?? productPrice;
    const altBuyPrice = myAltProductPrice ?? altProductPrice;

    const isOnlyOnline =
        product?.Freight_Object?.[0]?.FreightType === ShopTypes.FreightType.OnlyRetrieval;
    const hasAlternativeSalesUnit = !!altBuyPrice && altBuyPrice !== buyPrice;

    const salesUnit = translate('product.productCardSalesUnit').replace(
        '[SALES_UNIT]',
        productUnit,
    );
    const alternativeSalesUnit = hasAlternativeSalesUnit
        ? translate('product.productCardAlternativeSalesUnit')
              .replace('[ALTERNATIVE_SALES_UNIT]', altProductUnit)
              .replace('[ALTERNATIVE_SALES_PRICE]', formatCurrency(altBuyPrice))
        : null;
    
    const normalBuyPrice = myOriginalPrice ?? normalPrice;
    const validDiscount = !!normalBuyPrice && normalBuyPrice > buyPrice && !!showOriginalPrice;
    if (myPriceIsLoading) return PriceSkeleton();

    return (
        <PriceWrapper className={className}>
            <Amount>
                <BuyPrice as="span" discounted={validDiscount }>
                    {formatCurrency(buyPrice)}
                </BuyPrice>

                {validDiscount ? (
                    <NormalPrice>{formatCurrency(normalBuyPrice)}</NormalPrice>
                ) : null}
            </Amount>

            <SalesUnit>
                {salesUnit}
                {alternativeSalesUnit}
                {showPriceExVat
                    ? ' ' + translate('product.productCardSalesExVAT')
                    : !isOnlyOnline && translate('product.productCardSalesUnitFreight')}
            </SalesUnit>
        </PriceWrapper>
    );
};

function PriceSkeleton() {
    return (
        <PriceWrapper>
            <Amount>
                <SkeletonLoader height={22} width={40} />
            </Amount>

            <SalesUnit>
                <SkeletonLoader height={12} width={100} />
                <SkeletonLoader height={12} width={50} />
            </SalesUnit>
        </PriceWrapper>
    );
}
