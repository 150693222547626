import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { useState } from 'react';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { useBasketReference } from '~/shared/utils/storage/hooks';

export default function useBasket<SelectType = Basket>(select?: (data: Basket) => SelectType) {
    const [reference] = useBasketReference();
    const [failedToFetchOriginalBasket, setFailedToFetchOriginalBasket] = useState(false);

    const query = useQuery<Basket, HTTPError | Error, SelectType>(
        ['basket', { reference }],
        () => Shop.operations.getBasket(reference),
        {
            retry: (failureCount, error) => {
                if (error instanceof HTTPError && error.response?.status === 404) {
                    return false;
                }
                return failureCount < 2;
            },
            enabled: !!reference,
            onError: (error) => {
                if (error instanceof HTTPError && error.response?.status === 404) {
                    setFailedToFetchOriginalBasket(true);
                }
            },
            select,
            staleTime: 5 * 60 * 100, // 5 minutes
            cacheTime: 30 * 1000, // 30 seconds
            refetchOnWindowFocus: true,
            meta: {
                type: 'getBasket',
            },
        },
    );

    return { ...query, refetchBasket: query.refetch, failedToFetchOriginalBasket };
}

type Basket = ShopTypes.BasketResponse;
