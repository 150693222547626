import { ShopTypes } from '~/lib/data-contract';
import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent } from '../utils/types';
import { getGABasketItems } from '../utils/getGABasketItems';

export function viewCart(basket?: ShopTypes.BasketResponse) {
    if (!basket) return;
    pushToDataLayer({ ecommerce: null });
    pushToDataLayer({
        event: GAEcommerceEvent.ViewCart,
        ecommerce: {
            basketReference: basket.basketReference,
            currency: 'DKK',
            value: basket?.total?.priceInclVat,
            items: getGABasketItems(basket.contexts),
        },
    });
}
