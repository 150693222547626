/* eslint-disable import/no-duplicates */

import {
    addDays,
    addSeconds,
    differenceInCalendarDays,
    format,
    Locale,
    parseISO,
    subDays,
} from 'date-fns';
import { da } from 'date-fns/locale/da';

// Necessary because react-query service layer doesn't parse BE date strings into Date objects
export const ensureDateType = (date: string | Date) =>
    typeof date === 'string' ? parseISO(date) : date;

export const excludeWeekendDay = (date: Date) =>
    ensureDateType(date).getDay() !== 0 && ensureDateType(date).getDay() !== 6;

export const formatDateDayAndLongMonth = (date: Date, locale?: Locale) =>
    format(ensureDateType(date), 'd. MMMM', { locale: locale || getLocale() });

export const formatDateLongDayAndLongMonth = (date: Date, locale?: Locale) =>
    format(ensureDateType(date), "cccc 'd'. d. MMMM", { locale: locale || getLocale() });

export const formatDateLongDayAndDate = (date: Date, locale?: Locale) =>
    format(ensureDateType(date), "cccc 'd'. dd/MM/yyyy", { locale: locale || getLocale() });

export const formatDateAndTime = (date: Date, locale?: Locale) =>
    format(ensureDateType(date), "cccc 'd'. d. MMMM 'kl'. HH:mm", {
        locale: locale || getLocale(),
    });

export const getDateIntervals = (startDate: number, endDate: number) => {
    return [{ start: subDays(new Date(), startDate), end: addDays(new Date(), endDate) }];
};

export const getDifferenceInCalendarDays = (date: Date) =>
    differenceInCalendarDays(ensureDateType(date), new Date());

export const addSecondsToDate = (date: Date, seconds: number) =>
    addSeconds(ensureDateType(date), seconds);

export const getLocale = (locale?: string) => {
    switch (locale?.toLowerCase()) {
        case 'da':
        case 'da-dk': {
            return da;
        }

        default: {
            return da;
        }
    }
};
