import { pushToDataLayer } from '../utils/pushToDataLayer';
import { GAEcommerceEvent, GAEcommerceItem } from '../utils/types';

export function addToCart(
    value: number,
    items: GAEcommerceItem[],
    basketReference: string | undefined,
) {
    if (items.length) {
        pushToDataLayer({ ecommerce: null });
        pushToDataLayer({
            event: GAEcommerceEvent.AddToCart,
            ecommerce: {
                basketReference: basketReference,
                currency: 'DKK',
                value,
                items,
            },
        });
    }
}
