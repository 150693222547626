import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useActiveCustomerId } from '~/shared/utils/cookie/hooks';

export default function AuthGuard({ children }: { children: React.ReactNode }) {
    const { data } = useSession();
    const [, setCustomerId] = useActiveCustomerId();

    useEffect(() => {
        if (data?.error === 'RefreshAccessTokenError') {
            setCustomerId('');
            signOut();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.error]);

    return children;
}
