import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button';
import { EnergyLabel as EnergyLabelComponent } from '~/shared/components/EnergyLabel';
import { Link } from '~/shared/components/Link';
import { ButtonText, IconWrapper, Wrapper } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';
import { Price } from './components/Price';
import { StockStatus } from './components/StockStatus';
import { Amount, BuyPrice, SalesUnit } from './components/styled';
import { StyledOnlineText } from '~/shared/components/ProductOnlineStatus/styled';

export const Container = styled.div({
    minWidth: '155px',
    position: 'relative',

    [breakpoints.sm]: {
        minWidth: '240px',
    },
    [`@container Carousel (width < 725px)`]: {
        contain: 'layout',
        minWidth: '175px',
        position: 'relative',
    },
});

export const Card = styled(Link)(({ theme }) => ({
    background: theme.colors.offWhite,
    display: 'block',
    maxWidth: '100%',
    height: '100%',
    padding: theme.space[1],
    paddingBottom: theme.space[2],
    color: theme.colors.nightBlack,
    textDecoration: 'none',

    [breakpoints.md]: {
        padding: theme.space[2],
        paddingBottom: theme.space[2],
    },

    ['img']: {
        transition: 'transform .2s',
    },

    [':hover img']: {
        transform: 'scale(1.15)',
    },
}));

export const Thumbnail = styled.figure(({ theme }) => ({
    background: theme.colors.white,
    margin: 0,
    width: '100%',
    height: '200px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@container Carousel (width < 725px)`]: {
        height: '170px',
        width: '100%',
    },
}));

export const Details = styled.div(({ theme }) => ({
    display: 'block',
    paddingTop: 8,

    [breakpoints.md]: {
        paddingTop: theme.space[2],
    },
}));

export const Title = styled.span(({ theme }) => ({
    display: 'block',
    maxWidth: 208,
    marginBottom: theme.space[2],
}));

export const Brand = styled.span(({ theme }) => ({
    display: 'block',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.chicken,
    height: theme.lineHeights.chicken,
    fontWeight: theme.fontWeights.normal,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    marginBottom: 1,
}));

export const Name = styled.span(({ theme }) => ({
    '--lines': 3,
    fontWeight: theme.fontWeights.medium,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.guinea,
    display: '-webkit-box',
    WebkitLineClamp: 'var(--lines)',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    height: `calc(${theme.lineHeights.guinea} * var(--lines))`,

    [breakpoints.md]: {
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.dog,
        height: `calc(${theme.lineHeights.dog} * var(--lines))`,
    },

    [`@container Carousel (width < 725px)`]: {
        '--lines': 3,
        fontWeight: theme.fontWeights.medium,
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.guinea,
        display: '-webkit-box',
        WebkitLineClamp: 'var(--lines)',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        height: `calc(${theme.lineHeights.guinea} * var(--lines))`,
    },
}));

export const BottomCardDetail = styled.span(({ theme }) => ({
    flexDirection: 'column',
    gap: theme.space[1],
    display: 'grid',
    gridTemplateRows: '45px auto',
    gridTemplateAreas: `
    "price basket"
    "stock stock"
    `,

    [breakpoints.md]: {
        gap: theme.space[1],
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        gridTemplateRows: '3fr 4fr',
        gridTemplateAreas: `
        "stock stock"
        "price basket"
        `,
    },

    [`@container Carousel (width < 725px)`]: {
        display: 'grid',
        gridTemplateAreas: `
        "price basket"
        "stock stock"
        `,
        gridTemplateRows: '45px auto',
    },
}));

export const EnergyLabel = styled(EnergyLabelComponent)(({ theme }) => ({
    position: 'absolute',
    bottom: 155,
    left: theme.space[1],
    width: theme.space[7],

    [breakpoints.md]: {
        bottom: 230,
        left: theme.space[2],
    },

    [`@container Carousel (width < 725px)`]: {
        bottom: 170,
        left: theme.space[2],
    },
}));

export const VariantCount = styled.span(({ theme }) => ({
    bottom: 0,
    color: theme.colors.nightBlack,
    fontSize: theme.fontSizes.beetle,
    opacity: '50%',
    padding: theme.space[1],
    position: 'absolute',
    right: 0,
}));

export const StyledPrice = styled(Price)(({ theme }) => ({
    [breakpoints.md]: { alignItems: 'flex-start' },
    [`${Amount}`]: {
        alignSelf: 'flex-start',
    },

    [`${BuyPrice}`]: {
        fontSize: theme.fontSizes.chicken,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.dog,
        letterSpacing: -0.5,
    },
    [`@container Carousel (width < 725px)`]: {
        [`${SalesUnit}`]: {
            minHeight: 'auto',
            maxWidth: theme.space[12],
        },
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    justifySelf: 'end',
    height: 35,
    width: 35,
    bottom: 25,
    right: theme.space[2],

    [`${ButtonText}`]: {
        display: 'none',
    },

    [`${IconWrapper}`]: {
        svg: {
            width: 25,
            height: 25,
            strokeWidth: 1,

            path: {
                strokeWidth: 1,
            },
        },
    },

    [`${Wrapper}`]: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.space[3],

        [breakpoints.md]: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            [`${ButtonText}`]: {
                display: 'inline-block',
            },
        },
    },

    [breakpoints.md]: {
        position: 'absolute',
        width: '92px',
        bottom: theme.space[2],
        paddingLeft: theme.space[4],
        paddingRight: theme.space[3],
    },

    [`@container Carousel (width < 725px)`]: {
        position: 'absolute',
        justifySelf: 'end',
        height: theme.space[6],
        width: theme.space[6],
        bottom: 25,
        right: theme.space[2],
        paddingLeft: theme.space[1],
        paddingRight: theme.space[1],

        [`${Wrapper}`]: {
            display: 'flex',
            justifyContent: 'center',
            gap: theme.space[3],
            [`${ButtonText}`]: {
                display: 'none',
            },
        },
    },
}));

export const StyledStockStatus = styled(StockStatus)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gridArea: 'stock',
    gap: theme.space[4],

    [breakpoints.md]: {
        gap: theme.space[1],
        flexDirection: 'column',
    },
    [`${StyledOnlineText}`]: {
        fontSize: theme.fontSizes.beetle,
        lineHeight: theme.lineHeights.mouse,
    },
    [`@container Carousel (width < 725px)`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gridArea: 'stock',
        gap: 0,
    },
}));

export const SkeletonButton = styled.div(({ theme }) => ({
    position: 'absolute',
    justifySelf: 'end',
    height: 35,
    width: 35,
    bottom: 10,
    right: theme.space[2],
}));

export const SkeletonBottom = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[1],
}));
export const SkeletonTitle = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[1],
}));
