import { HTTPError } from 'ky';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ShopTypes } from '~/lib/data-contract';
import { Shop } from '~/services';
import { useBasketReference, useBasketTimer } from '~/shared/utils/storage/hooks';

export default function useCreateBasket() {
    const queryClient = useQueryClient();
    const [, setReference] = useBasketReference();
    const [, setTimer] = useBasketTimer();

    return useMutation<ShopTypes.BasketResponse, HTTPError | Error>(Shop.operations.createBasket, {
        onSuccess: (data) => {
            queryClient.setQueryData(['basket', { reference: data.basketReference }], data);
            setReference(data.basketReference);
            setTimer(data.updated.toString());
        },
        meta: {
            type: 'createBasket',
        },
    });
}
